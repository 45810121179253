import ListEngineUtil,{IListEngineDataObj} from "@/components/base_comp/listEngine/listEngineUtil";
import {watch, reactive, toRefs, onMounted, onBeforeMount, computed, getCurrentInstance, provide, nextTick, defineComponent, inject,onActivated} from 'vue'
import Condition from './utils/Condition.vue';
import {useRoute} from "vue-router";
/**
 * 可注册事件：
 * queryHandler【query-handler】查询按钮点击事件;
 * addHandler【add-handler】新增按钮点击事件;
 * editHandler【edit-handler】表格固定列编辑链接点击事件;
 * editHandler【edit-handler】表格固定列编辑链接点击事件;
 * deleteHandler【delete-handler】表格固定列删除链接点击事件;
 * afterPageData【after-page-data】请求后台完毕事件;
 *
 * props里面是外界可以传入的属性
 * */
const listEngine = defineComponent({
    name: "ListEngine",
    beforeRouteEnter(to, from, next) {
        next(async (engineInst:any)=>{
            if(engineInst.listParam.isBuildPageFromBack){
                await engineInst.utils.Event.dispatchEvent(to.fullPath+'_pageData', {modelMethod:to.fullPath,engineInst:engineInst});
            }
        })
    },
    props:{
        gridLoaded:{type: Function, default: async (options:any) => {}},//请求后台完毕之后，准备往表格放入数据
        formatPageInfo:{type: Function, default: async (options:any) => {return options}},//后台查询完成页面的查询条件、显示列之后，返回给前端之后，如果不满足则可以调用它来格式化一下
        beforeDelete:{type: Function, default: async (options:any) => {return true}},//表格固定列前面的删除链接点击之后暴露的接口
        afterDelete:{type: Function, default: (options:any) => {}},//表格固定列前面的删除链接删除数据之后暴露的接口
        setDelNoteMsg:{type: Function, default: async(options:any) => {return ''}},//设置删除提示语
    },
    //使用了gridTable控件，必须提供一下gridTableOwner，这里把listEngine的父类提供出去，
    //那么就可以在gridTable控件调用listEngine的父类的方法了
    setup(props,context){
        const instance = getCurrentInstance() as any;
        let {proxy}=instance;
        const utils=proxy.utils;
        const route = useRoute()
        let parentInst=instance.parent.proxy as any;
        provide('gridTableOwner', parentInst);
        let mainComp = inject('mainComp') as any;
        const dataObj:IListEngineDataObj=reactive<IListEngineDataObj>({
            utilInst: {} as any,
            refMap:new Map(),
            mainComp:mainComp,
            fullscreenLoading:true,//进入ListEngine的时候显示遮罩层，这个是局部遮罩，如果用指令方式就是整个页面都会遮罩
            exportType:0,//导出类别
            exportExcelVisible: false,//导出弹出框的控制/影藏控制字段
            backData: {} as any,//listEngine查询后台之后返回的结果

            collapseTitle:'',//由于查询条件可以折叠和打开，这个参数是折叠和打开的时候，显示的标题
            queryCondition:['queryCondition'],
            queryHeight:utils.Const.listEngineCollapseHeight,//查询条件折叠面板标题的高度，在显示之前，会重新计算该值
            queryConditionHeight: 0,//查询条件的高度，默认是0，如果要显示查询条件，需要在显示之前改变这个高度

            listButtons: [],//列表的操作按钮
            //listEngine的默认参数，该对象将会把从父类收到的参数和自己的默认参数合并，然后传递给gridTable控件
            //isShowFixCol-是否显示固定列（编辑、删除）；isShowBtnGroup-是否显示顶部按钮；isShowQueryParam-是否需要查询条件；
            //autoLoad-表格是否自动向后台发请求；expandQuery-是否展开查询条件；isBuildPageFromBack-是否从后台查询信息构建前台信息
            listParam: {queryParam:{},idField:"F_ID",isShowFixCol: true,isShowBtnGroup: true, isShowQueryParam: true,autoLoad:false,expandQuery:true,isBuildPageFromBack:true,columnList:[],paramHeight:0},
            otherParams:{
                componentLoadFlag:false,//组件是否已加载
                querySlots:[],//当后台构造查询条件的时候，里面是查询条件的name
                baseCalHeight:window.innerHeight,//表格计算高度的计算因子
                conditions:[]//查询条件，由后台返回赋值
            }
        })
        onActivated(()=>{
            nextTick(async ()=>{//MMP，关闭tab之后，再次打开，不会往后台再次发送请求，目前这样改了，第一次打开tab又会发送两次请求，哎
                dataObj.refMap.get('tbRef').queryHandler(true);
            })
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ListEngineUtil(proxy,dataObj,props,context);
            //初始化列表引擎，计算设置相关参数
            dataObj.utilInst.initListEngineParams();
        })
        onMounted(async ()=>{
            await doOnMounted();
            //监听窗口大小变化，主要是为了设置表格的最大高度
            window.onresize = ()=> {
                dataObj.otherParams.baseCalHeight=window.innerHeight;
                if(window.innerHeight){//这里有个bug，如果是弹出框包含的列表引擎，那么在改变窗口大小的时候，又把计算因子改为了window.innerHeight，本来该是弹出框的contentHeight作为计算因子才对
                    dataObj.utilInst.setGridTableContentMaxHeight(dataObj.otherParams.baseCalHeight);
                }
            }
        })
        const doOnMounted=async ()=>{
            await nextTick(async()=>{
                dataObj.otherParams.componentLoadFlag=true;
                await dataObj.utilInst.loadPageData();//请求后台，为grid和列表页其它数据项赋值
                if(dataObj.listParam.expandQuery){//如果默认是展开查询条件，则计算相关高度和设置相关显示信息
                    dataObj.queryHeight=dataObj.queryConditionHeight+utils.Const.listEngineCollapseHeight;
                    dataObj.collapseTitle=proxy.$t('listEngine.queryOpenTitle');
                }else{
                    dataObj.collapseTitle=proxy.$t('listEngine.queryFoldTitle');
                }
                dataObj.utilInst.setGridTableContentMaxHeight(dataObj.otherParams.baseCalHeight);
                utils.AnimateUtil.listBtnConditionAnimate();
            })
        }
        //顶部展示与影藏需要重新计算表格高度(弹出框中含有列表的时候,可能得不到mainComp,所以下方要判断一下)
        watch(() => {
            if(mainComp)return mainComp.otherParams.showTop;
            else return null;
        },async (newValue,oldValue) => {
            dataObj.utilInst.setGridTableContentMaxHeight(dataObj.otherParams.baseCalHeight);
        })
        //---------------------------computed---------------------------
        //是否具有删除权限
        const isShowDel=computed(()=>{
            return (row:any) => {
                let isShow=dataObj.backData.canDel;
                let canOperate=true;
                if(row['canOperate']!=undefined)canOperate=row['canOperate'];
                if(context.attrs['isShowDel'])isShow=(context.attrs['isShowDel'] as Function)(row);
                return isShow && canOperate;
            };
        })
        //是否具有编辑权限
        const isShowEdit=computed(()=>{
            return (row:any) => {
                let isShow=dataObj.backData.canEdit || dataObj.backData.canView;
                if(context.attrs['isShowEdit'])isShow=(context.attrs['isShowEdit'] as Function)(row);
                return isShow;
            };
        })

        //头部按钮事件
        const clickHandler=(ev:any,id:any)=>{
            //父组建有相应事件就调用父组建的事件，没有定义的事件就调用子组建本身定义好的事件
            if(dataObj.utilInst.callEvent(ev,null))return;
            proxy[ev]();
        }
        //查询按钮事件
        const queryHandler=(isNewQuery?:any)=>{
            if(isNewQuery==undefined)isNewQuery=true;
            dataObj.refMap.get('tbRef').queryHandler(isNewQuery);
        }
        //新增按钮事件
        const addHandler=async ()=>{
            await dataObj.utilInst.showCard({addOrEdit: 'add', id: ''});
        }
        //列表固定列编辑链接事件
        const editHandler=async (row:any)=>{
            if(dataObj.utilInst.callEvent('editHandler',{addOrEdit: 'edit', id: row[dataObj.listParam.idField]}))return;
            await dataObj.utilInst.showCard({addOrEdit: 'edit', id: row[dataObj.listParam.idField]});
        }
        //列表固定列删除链接事件
        const deleteHandler=async (row:any)=>{
            if(dataObj.utilInst.callEvent('deleteHandler',row))return;
            let delNoteMsg=await props.setDelNoteMsg(row);
            utils.Tools.configBox({
                message:delNoteMsg?delNoteMsg:proxy.$t('deleteNote'),
                sureFn:async ()=> {
                    await dataObj.utilInst.deleteData(row);
                }
            });
        }
        //dialog弹出框确认回调方法，返回false不会关闭弹出框,注意：如果parentInst有定义cancelHandler，那么一定要是async，否则在dialogHelper那里会报错
        const sureHandler=async (modelEngineRef:any)=>{
            if (parentInst['sureHandler']) {
                return await parentInst['sureHandler'](modelEngineRef);
            }else{
                return true;
            }
        }
        //dialog弹出框取消回调方法，返回false不会关闭弹出框,注意：如果parentInst有定义cancelHandler，那么一定要是async，否则在dialogHelper那里会报错
        const cancelHandler=async (modelEngineRef:any)=>{
            if (parentInst['cancelHandler']) {
                return await parentInst['cancelHandler'](modelEngineRef);
            }else{
                return true;
            }
        }
        //---------------导出excel---------------
        //列表头部的导出按钮事件
        const exportExcelHandler=()=>{
            dataObj.exportExcelVisible = true;
        }
        const sureExport=async()=>{
            await dataObj.utilInst.sureExport();
        }
        //---------------导出excel---------------


        const setTbData=(tbData:any)=>{//设置表格数据
            dataObj.refMap.get('tbRef').setTbData(tbData);
        }
        const getTbData=()=> {//得到表格数据
            return dataObj.refMap.get('tbRef').getTbData();
        }
        const getTbInst=()=>{//得到表格引用
            return dataObj.refMap.get('tbRef');
        }

        //设置列表指定id按钮是否可用
        const setBtnEnable=(val:boolean,id:string)=>{
            if(!id)id='add';//默认是新增按钮
            let btn:any=dataObj.listButtons.find((btn:any) => btn.id==id);
            if(btn)btn.disabled=val;
        }
        const gridLoaded=(res:any)=>{
            props.gridLoaded(res);
        }
        return{
            ...toRefs(dataObj),proxy,utils,isShowDel,isShowEdit,queryHandler,addHandler,route,
            sureHandler,cancelHandler,clickHandler,editHandler,deleteHandler,sureExport,exportExcelHandler,
            setTbData,getTbData,getTbInst,setBtnEnable,doOnMounted,gridLoaded
        }
    },
    components:{Condition}
});
export default listEngine;